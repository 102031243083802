import React, { useEffect } from "react";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoPerson, IoMail, IoShieldCheckmark, IoCash, IoBeer, IoTrashBin } from "react-icons/io5";

const Workers = (props) => {

    const SEARCH_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const [workers, setWorkers] = useState([]);
    const [search, setSearch] = useState("");
    const [shownWorkers, setShownWorkers] = useState([]);
    const [found, setFound] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [barman, setBarman] = useState(false);
    const [security, setSecurity] = useState(false);
    const [cashier, setCashier] = useState(false);

    //Carica i lavoratori
    useEffect(() => {
        Refresh();
    }, []);

    //Verifica la stringa di ricerca
    useEffect(() => {
        let temp = [];
        setShownWorkers([]);
        if (search.length > 0) {
            workers.filter((worker) => {
                if (worker.name.includes(search) || worker.surname.includes(search) || worker.email.includes(search)) {
                    temp.push(worker);
                }
            });
        }
        setShownWorkers(temp);
        setFound(false);
        if (search.match(SEARCH_REGEX)) {
            handleSearch();
        }
    }, [search]);

    //Aggiorna la lista dei lavoratori
    const Refresh = () => {
        fetch("https://backend.clubpass.it/organizer/workers", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.workers);
                setWorkers(data.workers);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //Rimuove un lavoratore
    const handleDelete = (id) => {
        fetch("https://backend.clubpass.it/organizer/workers/" + id, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setWorkers(workers.filter((worker) => worker.id !== id));
                }
            })
    }
    //Gestisce la ricerca di un nuovo lavoratore
    const handleSearch = () => {
        fetch("https://backend.clubpass.it/organizer/user", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: search }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setFound(true);
                }
                return response.json();
            })
            .then((data) => {
                setName(data.user.name);
                setSurname(data.user.surname);
                setEmail(data.user.email);
            })
            .catch((error) => {
                setFound(false);
            });
    };

    //Aggiorna il ruolo di un lavoratore
    const updateRole = (e, role, worker) => {
        if (role === "barman") {
            worker.role.barman = e.target.checked;
        } else if (role === "security") {
            worker.role.security = e.target.checked;
        } else if (role === "cashier") {
            worker.role.cashier = e.target.checked;
        }
        fetch("https://backend.clubpass.it/organizer/workers/" + worker.id, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: worker.email,
                organizer: worker.role.organizer ? 1 : 0,
                barman: worker.role.barman ? 1 : 0,
                security: worker.role.security ? 1 : 0,
                cashier: worker.role.cashier ? 1 : 0
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return true
                }
                return false;
            })
            .catch((error) => {
                console.log(error);
            });

    }
    //Aggiunge un nuovo lavoratore
    const addEmployee = () => {
        fetch("https://backend.clubpass.it/organizer/workers", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email, organizer: 0, barman: barman ? 1 : 0, security: security ? 1 : 0, cashier: cashier ? 1 : 0 }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setFound(false);
                    setSearch("");
                    Refresh();
                }
            })

    };

    if (window.innerWidth < 600) {
        return (
            <div style={{ minHeight: "300px" }}>
                <div className="form__group">
                    <input className="form__field" placeholder="Cerca..." value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    <label className="form__label">Cerca...</label>
                </div>
                {found ? <div className="card" style={{ width: "fit-content", marginTop: "10px", backgroundColor: "#242424" }}>
                    <h4>{name} {surname}</h4>
                    <div style={{ textAlign: "center", width: "fit-content", display: "inline-block" }}>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="barman"
                                name="barman"
                                value="barman"
                                onChange={(e) => setBarman(e.target.checked)} />
                            <label htmlFor="barman">Barman</label>
                        </div>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="security"
                                name="security"
                                value="security"
                                onChange={(e) => setSecurity(e.target.checked)} />
                            <label htmlFor="security">Sicurezza</label>
                        </div>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="cashier"
                                name="cashier"
                                value="cashier"
                                onChange={(e) => setCashier(e.target.checked)} />
                            <label htmlFor="cashier">Cassiere</label>
                        </div>
                    </div>
                    <button className="button" onClick={addEmployee}>Aggiungi</button>
                </div> : (search !== "" ? <p>Nessun utente trovato</p> : null)}
                {shownWorkers.length > 0
                    ? <table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Bar</th>
                                <th>Sec</th>
                                <th>Cassa</th>
                                <th>Elimina</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shownWorkers && shownWorkers.map((worker) => (
                                <tr key={worker.id}>
                                    <td style={{ lineHeight: "normal" }}>{worker.name} {worker.surname}</td>
                                    <td>{worker.email}</td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.barman}
                                        onChange={(e) => { updateRole(e, "barman", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.security}
                                        onChange={(e) => { updateRole(e, "security", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.cashier}
                                        onChange={(e) => { updateRole(e, "cashier", worker); }}
                                    /></td>
                                    <td>
                                        <button onClick={() => { handleDelete(worker.id) }}>
                                            <MdDelete size={25} style={{ verticalAlign: "middle" }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    : null
                }
                {search.length > 0 ? null :
                    <table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Bar</th>
                                <th>Sec</th>
                                <th>Cassa</th>
                                <th>Elimina</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workers && workers.map((worker) => (
                                <tr key={worker.id}>
                                    <td style={{ lineHeight: "normal" }}>{worker.name} {worker.surname}</td>
                                    <td>{worker.email}</td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.barman}
                                        onChange={(e) => { updateRole(e, "barman", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.security}
                                        onChange={(e) => { updateRole(e, "security", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.cashier}
                                        onChange={(e) => { updateRole(e, "cashier", worker); }}
                                    /></td>
                                    <td>
                                        <button onClick={() => { handleDelete(worker.id) }}>
                                            <MdDelete size={25} style={{ verticalAlign: "middle" }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        );
    } else {

        return (
            <div style={{ minHeight: "300px" }}>
                <div className="form__group">
                    <input className="form__field" placeholder="Cerca..." value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    <label className="form__label">Cerca...</label>
                </div>
                {found ? <div className="card" style={{ width: "fit-content", marginTop: "10px", backgroundColor: "#242424" }}>
                    <h4>{name} {surname}</h4>
                    <div style={{ textAlign: "center", width: "fit-content", display: "inline-block" }}>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="barman"
                                name="barman"
                                value="barman"
                                onChange={(e) => setBarman(e.target.checked)} />
                            <label htmlFor="barman">Barman</label>
                        </div>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="security"
                                name="security"
                                value="security"
                                onChange={(e) => setSecurity(e.target.checked)} />
                            <label htmlFor="security">Sicurezza</label>
                        </div>
                        <div>
                            <input style={{ accentColor: "#A37E2C" }}
                                type="checkbox"
                                id="cashier"
                                name="cashier"
                                value="cashier"
                                onChange={(e) => setCashier(e.target.checked)} />
                            <label htmlFor="cashier">Cassiere</label>
                        </div>
                    </div>
                    <button className="button" onClick={addEmployee}>Aggiungi</button>
                </div> : (search !== "" ? <p>Nessun utente trovato</p> : null)}
                {shownWorkers.length > 0
                    ? <table>
                        <thead>
                            <tr>
                                <th><IoPerson size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Nome</th>
                                <th><IoMail size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Email</th>
                                <th><IoBeer size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Bar</th>
                                <th><IoShieldCheckmark size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Sicurezza</th>
                                <th><IoCash size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Cassa</th>
                                <th><IoTrashBin size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Elimina</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shownWorkers && shownWorkers.map((worker) => (
                                <tr key={worker.id}>
                                    <td style={{ lineHeight: "normal" }}>{worker.name} {worker.surname}</td>
                                    <td>{worker.email}</td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.barman}
                                        onChange={(e) => { updateRole(e, "barman", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.security}
                                        onChange={(e) => { updateRole(e, "security", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.cashier}
                                        onChange={(e) => { updateRole(e, "cashier", worker); }}
                                    /></td>
                                    <td>
                                        <button onClick={() => { handleDelete(worker.id) }}>
                                            <MdDelete size={25} style={{ verticalAlign: "middle" }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    : null
                }
                {search.length > 0 ? null :
                    <table>
                        <thead>
                            <tr>
                                <th><IoPerson size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Nome</th>
                                <th><IoMail size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Email</th>
                                <th><IoBeer size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Bar</th>
                                <th><IoShieldCheckmark size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Sicurezza</th>
                                <th><IoCash size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Cassa</th>
                                <th><IoTrashBin size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} />Elimina</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workers && workers.map((worker) => (
                                <tr key={worker.id}>
                                    <td style={{ lineHeight: "normal" }}>{worker.name} {worker.surname}</td>
                                    <td>{worker.email}</td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.barman}
                                        onChange={(e) => { updateRole(e, "barman", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.security}
                                        onChange={(e) => { updateRole(e, "security", worker); }}
                                    /></td>
                                    <td><input type="checkbox"
                                        style={{ accentColor: "#A37E2C" }}
                                        defaultChecked={worker.role.cashier}
                                        onChange={(e) => { updateRole(e, "cashier", worker); }}
                                    /></td>
                                    <td>
                                        <button onClick={() => { handleDelete(worker.id) }}>
                                            <MdDelete size={25} style={{ verticalAlign: "middle" }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        );
    }

}

export default Workers;