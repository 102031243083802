import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";

export const EndedEventPage = () => {
    const location = useLocation();
    const event = location.state;
    const [newImages, setNewImages] = useState([]);
    const [completedImages, setCompletedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [rawImages, setRawImages] = useState([]);
    const [uploadComplete, setUploadComplete] = useState(false);


    useEffect(() => {
        fetch("https://backend.clubpass.it/organizer/events/" + event.id + "/photos", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return [];
                }
            })
            .then((data) => {
                if (data.images){
                    setImages(data.images);
                }
                else{
                    setImages([]);
                }
            })
    }, []);

    const photosHandler = (e) => {
        e.preventDefault();
        //set completedImages as an array of 0s with length equal to the number of images
        const tempArray = new Array(rawImages.length).fill(0);
        setCompletedImages(tempArray);
        for (let i = 0; i < rawImages.length; i++) {
            const formData = new FormData();
            formData.append("photo", rawImages[i]);
            fetch("https://backend.clubpass.it/organizer/events/" + event.id + "/photos", {
                method: "POST",
                credentials: "include",
                body: formData,
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then((data) => {
                    setCompletedImages((prev) => {
                        const temp = [...prev];
                        temp[i] = 1;
                        return temp;
                    });
                })
        }
    }

    useEffect(() => {
        if (completedImages.length > 0) {
            if (completedImages.every((value) => value === 1)) {
                fetch("https://backend.clubpass.it/organizer/events/" + event.id + "/photos", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        else {
                            return [];
                        }
                    })
                    .then((data) => {
                        if (data.images) {
                            setImages(data.images);
                            setUploadComplete(true);
                        }
                        else {
                            setImages([]);
                        }
                    })
            }
        }
    }, [completedImages]);

    const removeImage = (image) => {
        //Take last part of image url to get image id and remove .jpeg
        const imageId = image.imageUrl.slice(image.imageUrl.lastIndexOf("/") + 1, image.imageUrl.lastIndexOf("."));
        fetch("https://backend.clubpass.it/organizer/events/" + event.id + "/photos/" + imageId, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setImages(images.filter((img) => img !== image));
                }
            });
    }


    return (
        <div>
            <h1>Event Details</h1>
            <div className="card" style={{ width: "350px" }}>
                <h2>{event.name}</h2>
                <img src={event.iconUrl} alt="event" style={{ borderRadius: 10, width: 200, height: 200 }} />
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <div>
                        <p style={{ color: "Gray", marginTop: "auto" }}>Data</p>
                        <p style={{ marginTop: -20 }}>{
                            new Date(event.start).toLocaleDateString("it-IT", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })
                        }</p>
                    </div>
                    <div>
                        <p style={{ color: "Gray", marginTop: "auto" }}>Orario</p>
                        <p style={{ marginTop: -20 }}>{
                            new Date(event.start).toLocaleTimeString("it-IT", {
                                hour: "2-digit",
                                minute: "2-digit",
                            })
                        }</p>
                    </div>
                </div>
                <div>
                    <p style={{ color: "Gray", marginTop: "auto" }}>Luogo</p>
                    <p style={{ marginTop: -20 }}>{event.locationName}</p>
                </div>
                <div>
                    <p style={{ color: "Gray", marginTop: "auto" }}>Descrizione</p>
                    <p style={{ marginTop: -20 }}>{event.description}</p>
                </div>
                <hr />
                <h4>Inserisci immagini evento</h4>
                {
                    newImages.map((image, index) => {
                        return (

                            <div style={completedImages[index] === 1 ? { borderRadius: 10, width: 100, height: 100, margin: 5, overflow: "hidden", display: "inline-block", position: "relative", borderWidth: 2, borderColor: "green", borderStyle: "solid", borderColor: "green" }
                                : { borderRadius: 10, width: 100, height: 100, margin: 5, overflow: "hidden", display: "inline-block", position: "relative" }}>
                                <img key={image} src={image} style={{ width: "100%", height: "100%" }} alt="event" />
                                <IoIosCheckmark style={{ position: "absolute", bottom: 0, right: 0 }} color={completedImages[index] === 1 ? "green" : "transparent"} size={30} />
                            </div>
                        )
                    })
                }
                <form onSubmit={photosHandler}>
                    <input type="file" name="photos"
                        multiple
                        required
                        onChange={(e) => {
                            let images = [];
                            let rawImages = [];
                            for (let i = 0; i < e.target.files.length; i++) {
                                images.push(URL.createObjectURL(e.target.files[i]));
                                rawImages.push(e.target.files[i]);
                            }
                            setNewImages(images);
                            setRawImages(rawImages);
                        }}
                    />
                    <input type="submit" className="button" value="Invia" />
                </form>
                {images.length > 0 && <h4>Fotografie evento</h4>}
                {
                    images.map((image) => {
                        return (
                            <div style={{ borderRadius: 10, width: 100, height: 100, margin: 5, overflow: "hidden", display: "inline-block", position: "relative" }}>
                                <img key={image} src={image.iconUrl} style={{ width: "100%", height: "100%" }} alt="event" />
                                <IoClose style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }} size={30}
                                    onClick={() => removeImage(image)} />
                            </div>
                        )
                    })
                }
                {uploadComplete && <p style={{ color: "green" }}>Upload completato</p>}
            </div>
            <Navbar current="dashboard" />
        </div>
    );
}

export default EndedEventPage;