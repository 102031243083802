import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import ProductList from "../components/ProductList";
import { useState } from "react";
import EventList from "../components/EventList";
import Workers from "../components/Workers";
import EndedEventsList from "./EndedEventsListPage";
import EventData from "../components/EventData";

export const Dashboard = () => {
    

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
                //if on mobile, show the elements in a column
                window.innerWidth < 600 ? (
                    <div>
                        <h1>Dashboard</h1>

                        <div className="card">
                            <h2>Gestione eventi</h2>
                            <EventList />
                        </div>

                        <div className="card">
                            <h2>Prodotti</h2>
                            <div>
                                <ProductList />
                            </div>
                        </div>

                        <div className="card">
                            <h2>Personale</h2>
                            <Workers />
                        </div>

                    </div>
                ) : (
                    //if on desktop, show the elements in one wide column
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                        <div style={{ height: "100%", width: 200 }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: window.innerWidth - 200, float: "right" }}>
                            <h1>Dashboard</h1>
                            <EventData />

                            <div className="card">
                                <h2>Gestione eventi</h2>
                                <EventList />
                            </div>

                            <div className="card">
                                <h2>Prodotti</h2>
                                <div>
                                    <ProductList />
                                </div>
                            </div>

                            <div className="card" style={{ width: window.innerWidth - 400, maxWidth: "unset" }}>
                                <h2>Personale</h2>
                                <Workers />
                            </div>

                        </div>
                    </div>
                )
            }
            <Navbar current="dashboard" />
        </div >
    );

};
