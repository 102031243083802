import React from "react";
import { useState, useEffect } from "react";
import { IoBarcodeOutline, IoInformationCircle, IoPricetag, IoTicket } from "react-icons/io5";

const EventTickets = (props) => {

    const [items, setItems] = useState(null);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        if (props.eventID === -1) {
            return;
        }
        fetch('https://backend.clubpass.it/organizer/events/' + props.eventID + '/entrances',
            {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setItems(data.tickets);
            })
    }


    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>{window.innerWidth > 600 ? <IoTicket size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} /> : null} Nome</th>
                        <th>{window.innerWidth > 600 ? <IoInformationCircle size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} /> : null} Tipo</th>
                        <th>{window.innerWidth > 600 ? <IoBarcodeOutline size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} /> : null} Codice</th>
                        <th>{window.innerWidth > 600 ? <IoPricetag size={16} style={{ marginRight: 8, verticalAlign: "text-top" }} /> : null} Prezzo</th>
                    </tr>
                </thead>
                <tbody>
                    {items && items.map((item) => (
                        <tr>
                            <td>{item.siaeTypeName}</td>
                            <td>{item.type}</td>
                            <td>{item.siaeTypeCode}</td>
                            <td>{item.price} €</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EventTickets;