import React from "react";
import { Html5Qrcode } from "html5-qrcode";
import Navbar from "../components/Navbar";
import GridElement from "../components/GridElement";
import { useEffect, useState } from "react";
import Alert from "../components/Alert";

const BarmanPage = () => {
    const [items, setItems] = useState();
    const [reader, setReader] = useState({});
    const [isReading, setIsReading] = useState(false);
    const [alertComponent, setAlertComponent] = useState({ type: "error", message: "Accesso negato", hidden: true });

    useEffect(() => {
        setReader(new Html5Qrcode("reader"));
        fetch('https://backend.clubpass.it/barman/items',
            {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((data) => {

                console.log('Success:', data);
                setItems(data);

            })
            .catch((err) => {
                console.error('Error', err);
            });

    }, []);

    const handleCharge = (decodedText, item) => {

        fetch("https://backend.clubpass.it/barman/operations/additem",
            {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ qrcodeID: decodedText, itemID: item.id })
            })
            .then((response) => {
                if (response.status === 200) {
                    setAlertComponent({ type: "success", message: "Caricamento effettuato", hidden: false });
                } else if (response.status === 401) {
                    setAlertComponent({ type: "error", message: "Codice QR non valido(non abilitato o uscita già effettuata)", hidden: false });
                } else if (response.status === 400) {
                    setAlertComponent({ type: "error", message: "Qualcosa è andato storto", hidden: false });
                }
                response.json()
            })
            .catch((err) => {
                console.error('Error  ', err);
            });
    }

    const chargeItem = (item) => {
        setIsReading(true);
        window.scrollTo(0, 0);
        reader.start(
            { facingMode: "environment" },
            { fps: 10, qrbox: 250 },
            (decodedText, decodedResult) => {
                setIsReading(false);
                QRScannerStop();
                handleCharge(decodedText, item);
            },
            (errorMessage) => {
                console.log(`Error: ${errorMessage}`);
            }
        ).then((ignore) => {
            console.log("Started continous decode from camera. Ready to scan QR code.");
        }).catch((err) => {
            console.log(`Error starting the HTML5 QR code reader. ${err}`);
        });

    }

    const QRScannerStop = () => {
        reader.stop().then((ignore) => {
            setIsReading(false);
            console.log("HTML5 QR code reader stopped.");
        }).catch((err) => {
            console.log(`Error stopping the HTML5 QR code reader. ${err}`);
        });
    }

    const removeAlert = () => {
        setAlertComponent({ type: "error", message: "Accesso negato", hidden: true });
    }

    return (
        <div>
            <div hidden={!isReading} id="reader" style={{ width: "90% ", maxWidth: "500px", margin: "auto", borderRadius: "10px", overflow: "hidden" }} />
            <div hidden={!isReading}>
                <button className="button" onClick={() => QRScannerStop()}>Stop</button>
            </div>

            <div className="card">
                <h1>Lista prodotti</h1>
                <div hidden={alertComponent.hidden}>
                    <Alert type={alertComponent.type} message={alertComponent.message} remove={removeAlert} />
                </div>
                <div className="grid-container">
                    {
                        items && items.message.map((item) => (
                            <GridElement key={item.id} item={item} onAction={() => chargeItem(item)} />
                        ))
                    }
                </div>
            </div>
            <Navbar current="scanner" />
        </div>
    )


}

export default BarmanPage;