import React from 'react';

const Loader = () => {
  return (
      <div className="loading-wave">
        <div className="loading-bar" />
        <div className="loading-bar" />
        <div className="loading-bar" />
        <div className="loading-bar" />
      </div>
  );
}

export default Loader;