import React from "react";
import { useState, useEffect } from "react";

export const Alert = (props) => {
    
    const remove = () => {
        props.remove();
    }
    return (
        <div className={"alert " + props.type}>
            {props.message}
            <span className="closebtn" onClick={remove}>x</span>
        </div>
    )
}

export default Alert;
