import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { AiFillDashboard } from "react-icons/ai";
import { MdQrCodeScanner } from "react-icons/md";
import { FaListAlt } from "react-icons/fa";
import clubPass from "../images/clubpass.png";

const Navbar = (props) => {

    const auth = useAuth();
    const [count, setCount] = useState(3);

    useEffect(() => {
        let localCount = 3;
        if (auth.auth.role.organizer === 1) {
            localCount++;
        }
        if (auth.auth.role.cashier === 1 || auth.auth.role.barman === 1 || auth.auth.role.security === 1) {
            localCount++;
        }
        setCount(localCount);

    }, [auth.auth.role.organizer, auth.auth.role.cashier, auth.auth.role.barman, auth.auth.role.security]);
    const width = 100 / count + "%";
    return (
        <div>
            {window.innerWidth < 600 ? // If screen width is less than 600px, show the navbar as a
                <div className="navbar">

                    <Link to="/"
                        className={props.current === "home" ? "Link active" : "Link"}
                        style={{ width: width }}
                        id="home">
                        <AiFillHome size={30} />
                    </Link>

                    <Link to="/summary"
                        className={props.current === "summary" ? "Link active" : "Link"}
                        style={{ width: width }}
                        id="summary">
                        <FaListAlt size={30} />
                    </Link>

                    {auth.auth.role.organizer === 1 ?
                        <Link to="/dashboard"
                            className={props.current === "dashboard" ? "Link active" : "Link"}
                            style={{ width: width }}
                            id="dashboard">
                            <AiFillDashboard size={30} />
                        </Link> : null}

                    {auth.auth.role.security === 1 || auth.auth.role.cashier === 1 || auth.auth.role.barman === 1 ?
                        <Link to="/scanner"
                            className={props.current === "scanner" ? "Link active" : "Link"}
                            style={{ width: width }}
                            id="scanner">
                            <MdQrCodeScanner size={30} />
                        </Link> : null}

                    <Link to="/preferences"
                        className={props.current === "preferences" ? "Link active" : "Link"}
                        style={{ width: width }}
                        id="preferences">
                        <AiFillSetting size={30} />
                    </Link>

                </div> :
                // If screen width is more than 600px, show the navbar as a side bar with icons and page names side by side
                <div className="navbar" style={{width: "200px"}}>
                    <img src={clubPass} alt="ClubPass" style={{ width: "150px", left: "20px" }} />
                    <Link to="/"
                        className={props.current === "home" ? "Link active" : "Link"}
                        style={{ maxWidth: "150px" }}
                        id="home">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <AiFillHome size={20} />
                            <span style={{ width: "10px" }}></span>
                            <p>Home</p>
                        </div>
                    </Link>

                    <Link to="/summary"
                        className={props.current === "summary" ? "Link active" : "Link"}
                        style={{ maxWidth: "150px" }}
                        id="summary">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <FaListAlt size={20} />
                            <span style={{ width: "10px" }}></span>
                            <p>Riepilogo</p>
                        </div>
                    </Link>

                    {auth.auth.role.organizer === 1 ?
                        <Link to="/dashboard"
                            className={props.current === "dashboard" ? "Link active" : "Link"}
                            style={{ maxWidth: "150px" }}
                            id="dashboard">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <AiFillDashboard size={20} />
                                <span style={{ width: "10px" }}></span>
                                <p>Dashboard</p>
                            </div>
                        </Link> : null}

                    {auth.auth.role.security === 1 || auth.auth.role.cashier === 1 || auth.auth.role.barman === 1 ?
                        <Link to="/scanner"
                            className={props.current === "scanner" ? "Link active" : "Link"}
                            style={{ maxWidth: "150px" }}
                            id="scanner">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <MdQrCodeScanner size={20} />
                                <span style={{ width: "10px" }}></span>
                                <p>Scanner</p>
                            </div>
                        </Link> : null}

                    <Link to="/preferences"
                        className={props.current === "preferences" ? "Link active" : "Link"}
                        style={{ maxWidth: "150px" }}
                        id="preferences">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <AiFillSetting size={20} />
                            <span style={{ width: "10px" }}></span>
                            <p>Preferenze</p>
                        </div>
                    </Link>

                </div>
            }
        </div>
    );

}

export default Navbar;