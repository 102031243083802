import React from "react";
import { Html5Qrcode } from "html5-qrcode";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import GridElement from "../components/GridElement";
import GridListElement from "../components/GridListElement";
import Alert from "../components/Alert";

const SecurityPage = () => {

    const [reader, setReader] = useState({});
    const [isReading, setIsReading] = useState(false);

    const [alertComponent, setAlertComponent] = useState({ type: "error", message: "Accesso negato", hidden: true });
    const [tickets, setTickets] = useState([]);


    useEffect(() => {
        setReader(new Html5Qrcode("reader"));
        fetch("https://backend.clubpass.it/security/enabler", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {return response.json()} )
            .then((data) => {
                if (data.prices)
                {
                    setTickets(data.prices);
                }
            })
            .catch((error) => {
                console.log(error);
            }
            );

    }, []);


    const removeAlert = () => {
        setAlertComponent({ type: "error", message: "Accesso negato", hidden: true });
    }

    const handleEntrance = (decodedText, ticket) => {
        console.log(decodedText);
        fetch("https://backend.clubpass.it/security/enabler", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ qrcodeID: decodedText, typeID: ticket.id }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setAlertComponent({ type: "success", message: "Accesso consentito con biglietto: " + ticket.type, hidden: false });
                } else {
                    setAlertComponent({ type: "error", message: "Accesso negato", hidden: false });
                }
            })
    }

    const handleExit = (decodedText) => {
        fetch("https://backend.clubpass.it/security/operations/paid", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ qrcodeID: decodedText }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setAlertComponent({ type: "success", message: "Uscita confermata", hidden: false });
                } else if (response.status === 401) {
                    setAlertComponent({ type: "error", message: "Codice QR non valido(non abilitato o uscita già effettuata)", hidden: false });
                } else if (response.status === 400) {
                    setAlertComponent({ type: "error", message: "Saldo non pagato", hidden: false });
                }
            })
    }

    const checkEntrance = (ticket) => {
        setIsReading(true);
        window.scrollTo(0, 0);
        reader.start(
            { facingMode: "environment" },
            { fps: 10, qrbox: 250 },
            (decodedText, decodedResult) => {
                setIsReading(false);
                QRScannerStop();
                handleEntrance(decodedText, ticket);
            },
            (errorMessage) => {
                console.log(`Error: ${errorMessage}`);
            }
        ).then((ignore) => {
            console.log("Started continous decode from camera. Ready to scan QR code.");
        }).catch((err) => {
            console.log(`Error starting the HTML5 QR code reader. ${err}`);
        });
    }

    const checkExit = () => {
        setIsReading(true);
        window.scrollTo(0, 100);
        reader.start(
            { facingMode: "environment" },
            { fps: 10, qrbox: 250 },
            (decodedText, decodedResult) => {
                setIsReading(false);
                QRScannerStop();
                handleExit(decodedText);
            },
            (errorMessage) => {
                console.log(`Error: ${errorMessage}`);
            }
        ).then((ignore) => {
            console.log("Started continous decode from camera. Ready to scan QR code.");
        }).catch((err) => {
            console.log(`Error starting the HTML5 QR code reader. ${err}`);
        });
    }

    const QRScannerStop = () => {
        reader.stop().then((ignore) => {
            setIsReading(false);
            console.log("HTML5 QR code reader stopped.");
        }).catch((err) => {
            console.log(`Error stopping the HTML5 QR code reader. ${err}`);
        });
    }

    return (
        <div style={{ textAlign: "center", margin: "auto", maxWidth: "600px"}}>
            <div hidden={!isReading} id="reader" style={{ width: "90% ", maxWidth: "500px", margin: "auto", borderRadius: "10px", overflow: "hidden" }} />
            <div hidden={!isReading}>
                <button className="button" onClick={() => QRScannerStop()}>Stop</button>
            </div>
                <h1>Ingressi</h1>
                <div>
                    {tickets.map((ticket) => (
                        <GridListElement key={ticket.type} item={{ name: ticket.type, price: ticket.price }} onAction={() => checkEntrance(ticket)} />
                    ))}
                    {/*<GridElement item={{ name: "Ingresso", price: null }} onAction={() => checkEntrance({name: "Ingresso", price: 0})} />}
                    <GridElement item={{ name: "Uscita", price: null }} onAction={() => checkExit()} />*/}
                </div>
                <hr style={{ width: "80%", maxWidth: "500px", margin: "auto" }} />
                <button className="button" style={{paddingInline: 30}} onClick={() => checkExit()}>Convalida Uscita</button>
                <div hidden={alertComponent.hidden}>
                    <Alert type={alertComponent.type} message={alertComponent.message} remove={removeAlert} />
                </div>
            <Navbar current="scanner" />
        </div>
    );

}

export default SecurityPage;