import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";

const SummaryPage = () => {


    const [total, setTotal] = useState(0);
    const [purchases, setPurchases] = useState([]);

    useEffect(() => {
        fetch("https://backend.clubpass.it/user/purchases", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                },
                })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    let temp = 0;
                    for (let i = 0; i < data.items.length; i++) {
                        temp += data.items[i].price;
                    }
                    setTotal(temp);
                    setPurchases(data.items);
                })
                .catch((error) => {
                    console.log(error);
                });
    }, []);

    const handlePayment = () => {
        fetch("https://backend.clubpass.it/user/pay", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setPurchases([]);
                        setTotal(0);
                    }
                })
    }


    return (
        <div>
            
        <div className="card">
            <h1>Riepilogo</h1>
            <table style={{border: "unset", width: "250px"}}>
                    <tbody>                        {
                            purchases.length > 0 && purchases.map((purchase, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{textAlign: "left", padding: "0px", border: "0px"}}>{purchase.name}</td>
                                        <td style={{textAlign: "right", padding: "0px", border: "0px"}}>€ {purchase.price}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <hr/>
                <table style={{border: "unset", width: "250px"}}>
                    <tbody>
                        <tr>   
                            <td style={{textAlign: "left", padding: "0px", border: "0px"}}>Totale</td>
                            <td style={{textAlign: "right", padding: "0px", border: "0px"}}>€ {total.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
                <button className="button" onClick={handlePayment}>Effettua Pagamento</button>
        </div>
        <Navbar current="summary"/>
        </div>
    )
}

export default SummaryPage;