import React from "react";

const GridElement = (props) =>
{

    return(
        <div className="grid-item">
            <h5>{props.item.name}</h5>
            <h5 hidden={props.item.price === null}>{props.item.price===null ? null : props.item.price.toFixed(2)} €</h5>
            <button
            style={{bottom: "0", position: "relative", fontSize: "1rem"}}
            className="button" onClick={props.onAction}>{props.item.price === null ? "Verifica" : "Aggiungi"}</button>
        </div>
    );
}

export default GridElement;