import React from "react";
import { IoTicketOutline } from "react-icons/io5";
import { MdQrCodeScanner } from "react-icons/md";

const GridListElement = (props) =>
{

    return(
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: "1.5rem"}} className="grid-item">
            <IoTicketOutline style={{marginInline: "10px", fontSize: "2rem"}} />
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "50%"}}>
            <h5 style={{fontSize: "1rem"}}>{props.item.name}</h5>
            <h5 style={{fontSize: "1rem"}} hidden={props.item.price === null}>{props.item.price.toFixed(2)} €</h5>
            </div>
            <button
            style={{bottom: "0", right: "0", position: "relative", fontSize: "2rem", width: "80px", minWidth: "0px", padding: 10}}
            className="button" 
            onClick={props.onAction}><MdQrCodeScanner scale={25}/></button>
        </div>
    );
}

export default GridListElement;