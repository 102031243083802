import { Routes,Route } from 'react-router-dom'
import './App.css';
import React, { useState } from 'react';
import { RequireAuth } from './components/RequireAuth';
import { Layout } from './components/Layout'
import { HomePage } from './pages/HomePage';
import { Login } from './pages/Login';
import { Registration } from './pages/Registration';
import { Dashboard } from './pages/Dashboard';
import { Preferences } from './pages/Preferences';
import { Unauthorized } from './pages/Unauthorized';
import { Missing } from './pages/Missing';
import LoginAuth from './pages/LoginAuth';
import Scanner from './pages/Scanner';
import BarmanPage from './pages/BarmanPage';
import SecurityPage from './pages/SecurityPage';
import CounterPage from './pages/CounterPage';
import SummaryPage from './pages/SummaryPage';
import EventPage from './pages/EventPage';
import EndedEventPage from './pages/EndedEventPage';
import EndedEventsListPage from './pages/EndedEventsListPage';
import ResetPassword from './pages/ResetPassword';


function App() {
  

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Registration />} />
        <Route path='login/auth'element={<LoginAuth />} />
        <Route path='unauthorized' element={<Unauthorized />} />
        <Route path='reset'element={<ResetPassword />} />

        <Route element={<RequireAuth  allowedRoles={["user"]}/>}>
          <Route path='/' element={<HomePage />} />
          <Route path='summary' element={<SummaryPage />} />
          <Route path='preferences' element={<Preferences />} />
        </Route>

        <Route element={<RequireAuth  allowedRoles={["organizer"]}/>}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='event-details' element={<EventPage />} />
          <Route path='ended-events-list' element={<EndedEventsListPage />} />
          <Route path='ended-event-details' element={<EndedEventPage />} />
        </Route>

        <Route element={<RequireAuth  allowedRoles={["barman","security", "organizer", "cashier"]}/>}>
          <Route path='scanner' element={<Scanner />} />
        </Route>

        <Route element={<RequireAuth  allowedRoles={["barman","organizer"]}/>}>
          <Route path='scanner/barman' element={<BarmanPage />} />
        </Route>

        <Route element={<RequireAuth  allowedRoles={["security", "organizer"]}/>}>
          <Route path='scanner/security' element={<SecurityPage />} />
        </Route>

        <Route element={<RequireAuth  allowedRoles={["cashier", "organizer"]}/>}>
          <Route path='scanner/counter' element={<CounterPage />} />
        </Route>

        <Route path='*' element={<Missing />} />
        
      </Route>
      
    </Routes>
  );
}

export default App;
