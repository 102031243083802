import React from "react";
import { useState, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";


export const EndedEventsListPage = () => {

    const [events, setEvents] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);

    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://backend.clubpass.it/organizer/events/ended/" + year + "/" + month, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return [];
                }
            })
            .then((data) => {
                setEvents(data);
            })
    }, [year, month]);


    const handleRefresh = (e) => {
        e.preventDefault();
    }



    const EventClickHandler = (event) => {
        navigate(`/ended-event-details`, { state: event });
    }

    return (
        <div>
            <div className="card">
                <h1>Eventi terminati</h1>
                <form onSubmit={handleRefresh} >
                    <div className="form__group" style={{ width: 100}}>
                        <select
                            className="form__field"
                            style={{ width: 100 }}
                            name="year" id="year" value={year} onChange={(e) => setYear(e.target.value)}>
                            <option style={{ color: "black" }} value="2021">2021</option>
                            <option style={{ color: "black" }} value="2022">2022</option>
                            <option style={{ color: "black" }} value="2023">2023</option>
                            <option style={{ color: "black" }} value="2024">2024</option>
                            <option style={{ color: "black" }} value="2025">2025</option>
                        </select>
                        <label className="form__label" htmlFor="year">Anno</label>
                    </div>
                    <div className="form__group" style={{ width: 100}}>
                        <select
                            className="form__field"
                            style={{ width: 100 }}
                            name="month" id="month" value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option style={{ color: "black" }} value="1">Gennaio</option>
                            <option style={{ color: "black" }} value="2">Febbraio</option>
                            <option style={{ color: "black" }} value="3">Marzo</option>
                            <option style={{ color: "black" }} value="4">Aprile</option>
                            <option style={{ color: "black" }} value="5">Maggio</option>
                            <option style={{ color: "black" }} value="6">Giugno</option>
                            <option style={{ color: "black" }} value="7">Luglio</option>
                            <option style={{ color: "black" }} value="8">Agosto</option>
                            <option style={{ color: "black" }} value="9">Settembre</option>
                            <option style={{ color: "black" }} value="10">Ottobre</option>
                            <option style={{ color: "black" }} value="11">Novembre</option>
                            <option style={{ color: "black" }} value="12">Dicembre</option>
                        </select>
                        <label className="form__label" htmlFor="month">Mese</label>
                    </div>
                </form>
                {events.length === 0 ? <p className="istructions" style={{ width: "120%"}}>La ricerca per il periodo inserito non ha prodotto risultati. 
                    Ciò potrebbe significare che non sono stati programmati eventi in tale periodo o che le informazioni relative a questi eventi non sono ancora disponibili.</p> :
                    events.map((event) => {

                        
                        return (
                            <button key={event.id} className="small-card" style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }} onClick={() => EventClickHandler(event)}>
                                <img src={event.iconUrl} alt="event" style={{ borderRadius: 10, width: 50, height: 50, }} />
                                <p style={{ marginInline: 5 }}>{event.name}</p>
                                <p>{
                                    new Date(event.start).toLocaleDateString("it-IT")
                                }</p>
                                <IoIosArrowForward style={{ verticalAlign: "middle" }} />
                            </button>
                        )
                    })
                }
            </div>
            <Navbar current="dashboard" />
        </div>

    );
};

export default EndedEventsListPage;