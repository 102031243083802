import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {

    //Minimum eight characters, maximum 24, at least one letter and one number
    const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,24}$/
    //Minimum three characters, maximum 24, beginning with a letter
    const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [code, setCode] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [email, setEmail] = useState("");
    const [pwd1Focus, setPwd1Focus] = useState(false);
    const [passwordScreen, setPasswordScreen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isEqual, setIsEqual] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);

    useEffect(() => {
        if (params.get("code")) {
            setCode(params.get("code"));
            setPasswordScreen(true);
        } else {
            setPasswordScreen(false);
        }
    }, []);

    useEffect(() => {
        if (PASSWORD_REGEX.test(password1)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [password1]);

    useEffect(() => {
        if (password1 === password2) {
            setIsEqual(true);
        } else {
            setIsEqual(false);
        }
    }, [password1, password2]);

    useEffect(() => {
        if (EMAIL_REGEX.test(email)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }, [email]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password1 === password2) {
            fetch("https://backend.clubpass.it/reset-password", {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    password: password1,
                    token: code,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setSuccess(true);
                    }
                }
                );
        }

    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        if (EMAIL_REGEX.test(email)) {
            fetch("https://backend.clubpass.it/reset-password", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: email,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsEmailSent(true);
                    }
                });
        }
    };

    return (
        <div>
            <div className="card" hidden={!passwordScreen} style={{ maxWidth: "300px"}} >
                <h1>Reset password</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form__group field">
                        <input
                            className="form__field"
                            type="password"
                            id="password1"
                            placeholder="Nuova password"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            onFocus={() => setPwd1Focus(true)}
                        />
                        <label htmlFor="password1" className="form__label">Nuova password</label>
                    </div>
                    <div className="form__group field">
                        <input
                            className="form__field"
                            type="password"
                            id="password2"
                            placeholder="Conferma password"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                        <label htmlFor="password2" className="form__label">Conferma password</label>
                    </div>
                    <p className="istructions" hidden={isValid || !pwd1Focus}>{"La password deve essere compresa tra 8 e 24 caratteri e deve contenere almeno una lettera e un numero"}</p>
                    <p className="istructions" hidden={isEqual || !isValid}>Le password non coincidono</p>
                    <input type="submit" className="button" value={"Cambia password"} />
                </form>
                <p className="istructions" hidden={!success} style={{ marginTop: "10px" }}>
                    Password cambiata con successo, ora puoi fare login
                    <Link to="/login">
                        Vai al login
                    </Link>
                </p>
            </div>
            <div className="card" hidden={passwordScreen} style={{ maxWidth: "300px" }}>
                <h1>Nuova password</h1>
                <form onSubmit={handleEmailSubmit}>
                    <p>Inserisci la tua email per ricevere un link per resettare la password</p>
                    <div className="form__group field" style={{ marginTop: "-10px" }}>
                    <input
                        className="form__field"
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="form__label">Email</label>
                    </div>
                    <p className="istructions" hidden={isEmailValid}>
                        Inserisci una email valida
                    </p>
                    <input className="button" type="submit" value={"Invia email"} />
                </form>
                <p className="istructions" hidden={!isEmailSent} style={{ marginTop: "10px" }}>
                    Email inviata con successo, controlla la tua casella di posta {email}
                </p>
            </div>
        </div>
    );
};

export default ResetPassword;