import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


const EventList = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);


    const showDetails = (event) => {
        navigate("/event-details", { state: event });
    }

    const createEvent = () => {
        //create empty event
        const event = {
            eventID: -1,//-1 means that the event is not in the database
            start: "",
            end: "",
            location: "",
            name: "",
            description: "",
            imageUrl: "",
            shownStartDate: "",
            shownEndDate: "",
            startTime: "",
            endTime: "",
            startDate: "",
            endDate: "",
        }

        navigate("/event-details", { state: event });
    }

    useEffect(() => {
        fetch("https://backend.clubpass.it/organizer/events", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                data.events.forEach(event => {
                    //add attribute shownStartDate to dd/mm/yyyy
                    event.shownStartDate = new Date(event.start).toLocaleDateString("it-IT");
                    //add attribute shownEndDate to dd/mm/yyyy
                    event.shownEndDate = new Date(event.end).toLocaleDateString("it-IT");
                    //add attribute startTime to hh:mm
                    event.startTime = new Date(event.start).toLocaleTimeString("it-IT").slice(0, 5);
                    //add attribute endTime to hh:mm
                    event.endTime = new Date(event.end).toLocaleTimeString("it-IT").slice(0, 5);
                    //add attribute startDate to yyyy-mm-dd
                    event.startDate = new Date(event.start).toISOString().slice(0, 10);
                    //add attribute endDate to yyyy-mm-dd
                    event.endDate = new Date(event.end).toISOString().slice(0, 10);
                });
                setEvents(data.events);
            })
            .catch((error) => {
            });
    }, []);




    return (
        <div>
            <div>
                {
                    events && events.map((event) => (
                        <button key={event.eventID} className="small-card"
                            style={{ fontSize: "15px", lineHeight: "1em", display: "block", height: "120px" }}
                            onClick={() => { showDetails(event) }}>
                            <img src={event.iconUrl} alt={event.name}
                                style={{ width: "110px", height: "110px", borderRadius: "10px", marginTop: "5px", marginBottom: "5px", display: "inline-block", float: "left" }}
                            />
                            <div style={{ display: "inline-block" }}>
                                <p style={{ fontWeight: "bold" }}>{event.name}</p>
                                <p>{event.location}</p>
                                <p>{event.shownStartDate}</p>
                            </div>
                        </button>
                    ))
                }
                <div>
                <button className="button" style={{ display: "inline-block"}} onClick={() => navigate("/ended-events-list")}>Eventi terminati</button>
                <span style={{ display: "inline-block", width: "15px" }}></span>
                <button className="button" style={{ display: "inline-block"}} onClick={createEvent}>Nuovo</button>
                </div>
            </div>
        </div>
    )

}

export default EventList;