import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QRCodeStyling from "qr-code-styling";
import { useAuth } from "../hooks/useAuth";
import Navbar from "../components/Navbar";


const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  margin: 10,
  dotsOptions: {
    type: "rounded",
    color: "#121212",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 5,
  },
  backgroundOptions: {
    color: "#FFFFFF",
  },
});

export const HomePage = () => {
  const [url, setUrl] = useState("wolfee.cloud");
  const [loaded, setLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [image, setImage] = useState("");
  const [name, setName] = useState("...");
  const [location, setLocation] = useState("...");
  const [time, setTime] = useState("...");
  const [description, setDescription] = useState("...");
  const ref = useRef(null);

  const navigate = useNavigate();

  const auth = useAuth();

  useEffect(() => {
    fetch("https://backend.clubpass.it/qrcode", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200){
          return response.json();
        }
      })
      .then((data) => {
          setUrl(data.message);
          setLoaded(true);
          getInfo();
      })
      .catch((err) => {
        console.error("Error  ", err);
      });
    qrCode.append(ref.current);
  }, []);



  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  //use effect for scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onRefresh = async (e) => {
    e.preventDefault();
    setLoaded(false);

    fetch("https://backend.clubpass.it/qrcode/regen", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200){
          return response.json();
        }
      })
      .then((data) => {
          setUrl(data.message);
          setLoaded(true);
      })
      .catch((err) => {
        console.error("Error  ", err);
      });
  };

  const getInfo = () => {
    fetch("https://backend.clubpass.it/qrcode/info", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200)
          return response.json();
        else
          return { info: { validated: false } };
      })
      .then((data) => {
        console.log("Success:", data);
        if (!data.info.validated) {
          setValidated(false);
        }
        else {
          setName(data.info.eventName);
          setLocation(data.info.locationName);
          setDescription(data.info.eventDescription);
          let startTime = new Date(data.info.eventStart).toLocaleTimeString("it-IT").slice(0, 5);
          setTime(startTime);
          setImage(data.info.eventIcon);
          setValidated(true);
        }
      })
  }

  const onDownloadClick = () => {
    qrCode.download({
      extension: "png",
    });
  };

  return (
    <div>
      <div className="card" style={{maxWidth: "350px"}}>
        <h2>Ciao {auth.auth.name}</h2>
        <div>
          <img
            style={{ width: "300px", height: "300px", borderRadius: "10px" }}
            hidden={!validated}
            src={image} />
          <div className="qr-canvas" hidden={!loaded} ref={ref}
            style={validated ? { marginTop: "-200px", display: "inline-block", transform: `translate( 0px, ${(50 - scrollY / 5) > 10 ? (50 - scrollY / 5) : 10}px` }
              : { display: "inline-block" }} />
        </div>
        <div style={{ marginTop: "30px" }} hidden={loaded}>
          <p>Caricamento...</p>
        </div>
        <div style={{ marginTop: "30px" }} hidden={!validated}>
          <p style={{ color: "Gray" }}>Evento</p>
          <h3 style={{ marginTop: "-20px" }}>{name}</h3>
          <div style={{ alignContent: "center" }}>
            <div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
              <p style={{ color: "Gray", marginTop: "auto" }}>Luogo</p>
              <p style={{ marginTop: "-20px" }}>{location}</p>
            </div>
            <div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
              <p style={{ color: "Gray", marginTop: "auto" }}>Orario</p>
              <p style={{ marginTop: "-20px" }}>{time}</p>
            </div>
          </div>
          <p style={{ color: "Gray", marginTop: "auto" }}>Descrizione</p>
          <p style={{ marginTop: "-20px" }}>{description}</p>
        </div>
        <div style={{ alignContent: "center" }}>
          <button
            className="button"
            style={{ display: "inline-block" }}
            onClick={onDownloadClick}
          >
            Download
          </button>
          <span style={{ marginLeft: "10px", marginRight: "10px" }}></span>
          <button
            className="button"
            style={{ display: "inline-block" }}
            onClick={onRefresh}
          >
            Rigenera
          </button>
        </div>
      </div>
      <Navbar current="home" />
    </div>
  );
};
