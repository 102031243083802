import React, { useEffect } from "react";
import { useState } from "react";
import Navbar from "../components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import EventWorkers from "../components/EventWorkers";
import EventProducts from "../components/EventProducts";
import EventTickets from "../components/EventTickets";

const EventPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state;
  const [name, setName] = useState(event.name);
  const [place, setPlace] = useState(event.location);
  const [startDate, setStartDate] = useState(event.startDate);
  const [endDate, setEndDate] = useState(event.endDate);
  const [startTime, setStartTime] = useState(event.startTime);
  const [endTime, setEndTime] = useState(event.endTime);
  const [image, setImage] = useState(event.imageUrl);//image url
  const [rawImage, setRawImage] = useState(null);//image file
  const [description, setDescription] = useState(event.description);


  //Salva le modifiche
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Event ID: " + event.eventID);
    //Controlla se è un nuovo evento
    if (event.eventID === -1) {
      //convert date and time to UTC YYYY-MM-DD HH:MM:SS
      let start = new Date(startDate + " " + startTime).toISOString();
      let end = new Date(endDate + " " + endTime).toISOString();
      //cut seconds
      start = start.substring(0, start.length - 8);
      end = end.substring(0, end.length - 8);
      //Invia i dati
      fetch("https://backend.clubpass.it/organizer/events", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          location: place,
          start: start,
          end: end,
          description: description,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          event.eventID = data.eventID;
          //invia l'immagine
          const formData = new FormData();
          formData.append("image", rawImage);
          fetch("https://backend.clubpass.it/organizer/events/" + event.eventID + "/image", {
            method: "POST",
            credentials: "include",
            body: formData,
          })
            .then((response) => {
              if (response.status === 200) {
                navigate("/dashboard");
              }
            })
        })
    } else {//Se non è un nuovo evento
      //Invia l'immagine
      if (rawImage !== null) {//Se l'immagine è stata cambiata
        const formData = new FormData();
        formData.append("image", rawImage);
        fetch("https://backend.clubpass.it/organizer/events/" + event.eventID + "/image", {
          method: "POST",
          credentials: "include",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          }
          )
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      //convert date and time to UTC YYYY-MM-DD HH:MM:SS
      let start = new Date(startDate + " " + startTime).toISOString();
      let end = new Date(endDate + " " + endTime).toISOString();
      //cut seconds
      start = start.substring(0, start.length - 8);
      end = end.substring(0, end.length - 8);
      //modifica l'evento
      fetch("https://backend.clubpass.it/organizer/events/" + event.eventID, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventID: event.eventID,
          name: name,
          location: place,
          start: start,
          end: end,
          description: description,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  //Elimina l'evento
  const handleDelete = () => {
    fetch("https://backend.clubpass.it/organizer/events/" + event.eventID, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          navigate("/dashboard");
        }
      })
  };

  //Annulla le modifiche
  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <div className="card" style={window.innerWidth < 600 ? { width: "90%" } : { width: "50%" }}>
        <h1>{event.name != "" ? event.name : "Nuovo evento"}</h1>
        <img
          src={image}
          alt={event.name}
          style={{ width: "75%", borderRadius: "10px", margin: "auto" }}
        />
        <form onSubmit={handleSubmit}>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={(e) => {
              setRawImage(e.target.files[0]);
              setImage(URL.createObjectURL(e.target.files[0]));
            }}
          />

          <label
            htmlFor="file"
            className="button"
            style={{ width: "fit-content", margin: "auto" }}
          >
            Carica immagine
          </label>
          <br />

          <div className="form__group">
            <input
              className="form__field"
              type="text"
              placeholder="Nome"
              defaultValue={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <label className="form__label">Nome</label>
          </div>
          <br />
          <div className="form__group">
            <input
              className="form__field"
              type="text"
              placeholder="Luogo"
              defaultValue={place}
              required
              onChange={(e) => setPlace(e.target.value)}
            />
            <label className="form__label">Luogo</label>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="form__group">
              <input
                className="form__field"
                type="date"
                defaultValue={startDate}
                required
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className="form__label">Data di inzio</label>
            </div>

            <div className="form__group">
              <input
                className="form__field"
                type="time"
                defaultValue={startTime}
                required
                onChange={(e) => setStartTime(e.target.value)}
              />
              <label className="form__label">Orario di inzio</label>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="form__group">

              <input
                className="form__field"
                type="date"
                defaultValue={endDate}
                required
                onChange={(e) => setEndDate(e.target.value)}
              />
              <label className="form__label">Data di fine</label>
            </div>
            <div className="form__group">

              <input
                className="form__field"
                type="time"
                defaultValue={endTime}
                required
                onChange={(e) => setEndTime(e.target.value)}
              />
              <label className="form__label">Orario di fine</label>
            </div>
          </div>

          <div className="form__group" style={{ width: "80%", maxWidth: "80%", marginTop: "30px" }}>
            <textarea
              style={{ height: "100px", width: "80%", resize: "none" }}
              className="form__field"
              type="text"
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <label className="form__label" style={{ left: "60px", top: "-5px" }}>Descrizione</label>
          </div>

          <input
            className="button"
            type="submit"
            value="Salva"
            style={{ fontSize: "15px" }}
          />
        </form>
        <button
          className="button"
          onClick={handleDelete}
          style={{ display: "inline-block" }}
        >
          Elimina
        </button>
        <span style={{ marginLeft: "10px", marginRight: "10px" }}></span>
        <button
          className="button"
          onClick={handleCancel}
          style={{ display: "inline-block" }}
        >
          Annulla modifiche
        </button>
      </div>
      <div className="card" style={window.innerWidth < 600 ? { width: "90%" } : { width: "50%" }}>
        <h2>Ingressi</h2>
        {event.eventID === -1 ? <p>Per visualizzare gli ingressi è necessario aver salvato l'evento</p> : <EventTickets eventID={event.eventID} />}
      </div>
      <div className="card" style={{ maxWidth: "350px", minWidth: "300px" }}>
        <h2>Prodotti</h2>
        {event.eventID === -1 ? <p>Per visualizzare i prodotti è necessario aver salvato l'evento</p> : <EventProducts eventID={event.eventID} />}
      </div>
      <div className="card" style={{ width: window.innerWidth - 400, maxWidth: "unset" }}>
        <h2>Personale</h2>
        {event.eventID === -1 ? <p>Per visualizzare gli addetti è necessario aver salvato l'evento</p> : <EventWorkers eventID={event.eventID} />}
      </div>
      <Navbar current="dashboard" />
    </div>
  );
};

export default EventPage;
