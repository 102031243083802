import React from "react";
import { Link } from "react-router-dom";

export const Unauthorized = () =>
{
    return(
        <div>
            <h1>Unauthorized</h1>
            <Link to={"/"}>Torna alla home</Link>
        </div>
    );
}