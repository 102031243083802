import React from "react";
import { useState, useEffect } from "react";

const EventProducts = (props) => {

    const [items, setItems] = useState(null);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0.99);
    const [hiddenResult, sethiddenResult] = useState(true);

    const addProduct = (e) => {
        e.preventDefault();
        fetch('https://backend.clubpass.it/organizer/events/' + props.eventID + '/items',
            {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([{
                    name: name,
                    price: price,
                    description: "",
                }])
            })
            .then((response) => { if (response.status === 200) return response.json() })
            .then((data) => {
                sethiddenResult(false);
                Refresh();
            })
            .catch((error) => {
            });
    }


    const Remove = (item) => {
        fetch('https://backend.clubpass.it/organizer/events/' + props.eventID + '/items/' + item.itemID,
            {
                method: 'DELETE',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((data) => {
                Refresh();
            })
    }

    const Refresh = () => {
        if (props.eventID === -1) {
            return;
        }
        fetch('https://backend.clubpass.it/organizer/events/' + props.eventID + '/items',
            {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((data) => {
                setItems(data.items);
            })

    }

    useEffect(() => {
        Refresh();
    }, []);


    return (
        <div>
            <div>
                <h4>Aggiungi Prodotto</h4>
                <form onSubmit={addProduct}>
                    <div className="form__group">
                        <input
                            type="text"
                            id="name"
                            className="form__field"
                            placeholder="Nome"
                            autoComplete="off"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            style={{ marginTop: "0px" }}
                            required
                        />
                        <label htmlFor="name" className="form__label">Nome</label>
                    </div>
                    <div className="form__group">
                        <input
                            type="number"
                            step={0.01}
                            min={0}
                            max={100}
                            id="price"
                            className="form__field"
                            autoComplete="off"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                            required
                        />
                        <label htmlFor="price" className="form__label">Prezzo (€)</label>
                    </div>
                    <input type="submit" value="Aggiungi" className="button" on />
                    <br />
                    <p className="istructions" hidden={hiddenResult}>Prodotto aggiunto con successo</p>
                </form>
            </div>
            {
                items ? items.map((item) => (
                    <div className="small-card" key={item.id} style={{ maxWidth: "300px" }}>
                        <h5
                            style={{
                                marginBottom: "auto",
                                marginTop: "auto",
                            }}
                        >{item.name}</h5>
                        <h5
                            style={{
                                marginBottom: "auto",
                                marginTop: "auto",
                            }}
                        >{item.price.toFixed(2)} €</h5>
                        <button
                            className="button"
                            style={{ marginBottom: "10px", marginTop: "10px", minWidth: "100px" }}
                            onClick={() => Remove(item)}>Rimuovi</button>
                    </div>
                )) : <div>Attualmente non ci sono prodotti per questo evento</div>
            }
        </div>
    );
}

export default EventProducts;