import React from "react";
import { Html5Qrcode } from "html5-qrcode";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import Alert from "../components/Alert";

const CounterPage = () => {

    const [reader, setReader] = useState({});
    const [isReading, setIsReading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [total, setTotal] = useState(0);
    const [qrcode, setQrcode] = useState("");
    const [purchases, setPurchases] = useState([]);
    const [alertComponent, setAlertComponent] = useState({ type: "error", message: "Accesso negato", hidden: true });

    useEffect(() => {
        setReader(new Html5Qrcode("reader"));
        console.log("loaded" + loaded);
    }, []);

    useEffect(() => {
        let total = 0;
        purchases.forEach((purchase) => {
            total += purchase.price;
        });
        setTotal(total);
    }, [purchases]);

    const handleCheck = (decodedText) => {
        fetch("https://backend.clubpass.it/cashier/operations/" + decodedText + "/purchases", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 204)
                {
                    setAlertComponent({ type: "info", message: "Pagamento già effettuato", hidden: false });
                }
                else {
                    setAlertComponent({ type: "error", message: "Accesso negato", hidden: false });
                }
            })
            .then((data) => {
                setLoaded(true);
                setPurchases(data.purchases);
            })
    }

    const checkCart = () => {
        setIsReading(true);
        window.scrollTo(0, 0);
        reader.start(
            { facingMode: "environment" },
            { fps: 10, qrbox: 250 },
            (decodedText, decodedResult) => {
                setIsReading(false);
                QRScannerStop();
                setQrcode(decodedText);
                handleCheck(decodedText);
            },
            (errorMessage) => {
                console.log(`Error: ${errorMessage}`);
            }
        ).then((ignore) => {
            console.log("Started continous decode from camera. Ready to scan QR code.");
        }).catch((err) => {
            console.log(`Error starting the HTML5 QR code reader. ${err}`);
        });

    }

    const clearCart = () => {
        fetch("https://backend.clubpass.it/cashier/operations/pay", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ qrcodeID: qrcode }),
        })
            .then((response) => {
                if (response.status === 200) {
                    setAlertComponent({ type: "success", message: "Pagamento effettuato", hidden: false });
                    setLoaded(false);
                    setPurchases([]);
                    setTotal(0);
                } else {
                    setAlertComponent({ type: "error", message: "Pagamento non effettuato", hidden: false });
                }
            });
    }


    const QRScannerStop = () => {
        reader.stop().then((ignore) => {
            setIsReading(false);
            console.log("HTML5 QR code reader stopped.");
        }).catch((err) => {
            console.log(`Error stopping the HTML5 QR code reader. ${err}`);
        });
    }

    const removeAlert = () => {
        setAlertComponent({ type: "error", message: "Accesso negato", hidden: true });
    }

    return (
        <div>
            <div hidden={!isReading} id="reader" style={{ width: "90% ", maxWidth: "500px", margin: "auto", borderRadius: "10px", overflow: "hidden" }} />
            <div hidden={!isReading}>
                <button className="button" onClick={() => QRScannerStop()}>Stop</button>
            </div>
            <div className="card">
                <div hidden={alertComponent.hidden}>
                    <Alert type={alertComponent.type} message={alertComponent.message} hidden={alertComponent.hidden} remove={removeAlert} />
                </div>
                <h1>Cassa</h1>
                <div hidden={loaded}>
                <button className="button" onClick={checkCart}>Verifica credito</button>
                </div>
                <h4>Acquisti</h4>
                <table style={{ border: "unset", width: "250px" }}>
                    <tbody>
                        {
                            purchases.length > 0 && purchases.map((purchase) => {
                                let id = 0;
                                return (
                                    <tr key={id++}>
                                        <td style={{ textAlign: "left", padding: "0px", border: "0px" }}>{purchase.name}</td>
                                        <td style={{ textAlign: "right", padding: "0px", border: "0px" }}>€ {purchase.price}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <hr />
                <table style={{ border: "unset", width: "250px" }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "left", padding: "0px", border: "0px" }}>Totale</td>
                            <td style={{ textAlign: "right", padding: "0px", border: "0px" }}>€ {total.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
                <div hidden={!loaded}>
                <button className="button" onClick={clearCart}>Conferma Pagamento</button>
                </div>
            </div>
            <Navbar current="scanner" />
        </div>

    )
}

export default CounterPage;