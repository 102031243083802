import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";


const Scanner = () => {

    const auth = useAuth();
    const navigate = useNavigate();

    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        if (auth.auth.role.organizer === 0 && auth.auth.role.cashier === 0 && auth.auth.role.barman === 0 && auth.auth.role.security === 1) {
            navigate("/scanner/security");
        }
        else if (auth.auth.role.organizer === 0 && auth.auth.role.cashier === 1 && auth.auth.role.barman === 0 && auth.auth.role.security === 0) {
            navigate("/scanner/counter");
        }
        else if (auth.auth.role.organizer === 0 && auth.auth.role.cashier === 0 && auth.auth.role.barman === 1 && auth.auth.role.security === 0) {
            navigate("/scanner/barman");
        }
        else
            {
                setIsHidden(false);
            }
    }, [auth.auth.role]);

    return (
        <div hidden={isHidden}>
            <div className="card" style={{ width: "250px" }}>
                <div hidden={auth.auth.role.security === 0}>
                <Link to="security"
                    style={{ margin: "10px", fontSize: "2em", color: "#d5d5d5", textDecoration: 'none', display: "flex", justifyContent: "space-between" }}>
                    Ingressi <IoIosArrowForward style={{ verticalAlign: "middle" }} />
                </Link>
                </div>

                <hr hidden={auth.auth.role.security === 0 || auth.auth.role.cashier === 0} />

                <div hidden={auth.auth.role.cashier === 0}>
                <Link to="counter"
                    
                    style={{ margin: "10px", fontSize: "2em", color: "#d5d5d5", textDecoration: 'none', display: "flex", justifyContent: "space-between" }}>
                    Cassa <IoIosArrowForward style={{ verticalAlign: "middle" }} />
                </Link>
                </div>

                <hr hidden={auth.auth.role.barman === 0} />

                <div hidden={auth.auth.role.barman === 0}>
                <Link to="barman"
                    style={{ margin: "10px", fontSize: "2em", color: "#d5d5d5", textDecoration: 'none', display: "flex", justifyContent: "space-between" }}>
                    Bar <IoIosArrowForward style={{ verticalAlign: "middle", right: "0" }} />
                </Link>
                </div>

            </div>
            <Navbar current="scanner" />

        </div>
    );
}

export default Scanner;