import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const RequireAuth = ({allowedRoles}) =>
{
    const auth = useAuth();
    const location = useLocation();
    const roles = [];
    if(!auth.auth.role)
    {
        return <Navigate to="/login/auth" state={{ from:location }} replace />;
    }
    for (const role in auth.auth.role) {
        if(auth.auth.role[role] === 1)
        {
            roles.push(role);
        }
    }
    roles.push("user");
    return(
        roles.find(role => allowedRoles.includes(role))
        ?
        <Outlet />
        : auth.auth.username
            ? <Navigate to="/unauthorized" state={{ from:location }} replace />
            :
            <Navigate to="/login/auth" state={{ from:location }} replace />

    );
}