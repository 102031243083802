import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const Preferences = () => {
    const [visibility, setVisibility] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        fetch("https://backend.clubpass.it/user/settings", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setIsLoaded(true);
                setVisibility(data.visible);
            })
            .catch((error) => {
                console.error('Error:', error);
            }
            );
    }

    const changeVisibility = () => {
        setVisibility(!visibility);
        fetch("https://backend.clubpass.it/user/settings/visible", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                visible: !visibility
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            }
            );

    }

    const onLogout = () => {
        fetch('https://backend.clubpass.it/logout',
            {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    navigate("/login")
                    return response.json();
                }
            })
    };


    return (
        <div>
            <div className="card" style={{Maxwidth:"300px"}}>
                <h1>Preferenze</h1>
                <div hidden={!isLoaded}>
                    <h3 style={{ display: "inline-block" }}>Visibilità Account</h3>
                    <span style={{ "marginLeft": "10px", "marginRight": "10px" }}></span>
                    <label className="switch" style={{ display: "inline-block" }}>
                        <input type="checkbox" checked={visibility} onChange={changeVisibility} />
                        <span className="slider"></span>
                    </label>
                </div>
                <button className="button" onClick={onLogout}>Logout</button>
            </div>
            <Navbar current="preferences" />
        </div>
    );
}